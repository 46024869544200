@import '../../../css/global';

.wrap {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  margin: 0 auto;
  text-transform: uppercase;
}

.title {
  line-height: 0.9;
  font-weight: 900;

  @include pxToClampMobile('font-size', auto, 34px, auto);
}

.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}

.subtitle {
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;

  @include pxToClampMobile('padding-top', auto, 16px, auto);
  @include pxToClampMobile('font-size', auto, 20px, auto);
}

.body {
  font-weight: 600;

  @include pxToClampMobile('padding-top', auto, 34px, auto);
  @include pxToClampMobile('font-size', auto, 15px, auto);
}

.button {
  display: block;
  appearance: none;
  width: percentage(calc(210 / 375));
  max-width: 210px;
  margin: 18px auto 0;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: #17181a;

  @include pxToClampMobile('padding', auto, 13px, auto);
  @include pxToClampMobile('margin-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);

  &.outline {
    background: none;
    color: #fff;
  }
}