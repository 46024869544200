@import '../../../css/global';

.Modal {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .wrap {
    width: percentage(calc(280 / 375));
    max-width: 280px;
    background-color: #fff;
    margin: 0 auto;
    padding: 50px 0px;
    border-radius: 8px;
  }
}
.title {
  width: percentage(calc(340 / 375));
  max-width: 340px;
  margin: 0 auto;

  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #000;

  @include pxToClampMobile('font-size', auto, 24px, auto);
}

.body {
  width: percentage(calc(290 / 375));
  max-width: 290px;
  margin: 0 auto;
  color: #000;


  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('padding-bottom', auto, 40px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);
}

.form {
}

.fields {

  select {
    display: block;
    appearance: none;
    width: 80%;
    max-width: 240px;
    border: 1px solid #000;

    padding: 11px 36px 11px 12px;
    margin: 0 auto;
    @include pxToClampMobile('font-size', auto, 12px, auto);

    background-image: url(../../../images/dropdown.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    border-radius: 6px;

    line-height: 1;
    font-weight: 600;
    color: #000;
  }

  &.multiple {
    display: flex;
    justify-content: center;

    select {
      width: 130px;
      margin: 0 5px;

      @media (min-width: 360px) {
        width: 140px;
      }
    }
  }

  + .fields {
    margin-top: 10px;
  }
}

.error {
  min-height: 10px;
  padding: 10px 0 0;
  font-weight: 600;
  color: #fdcbe0;

  @include pxToClampMobile('font-size', auto, 13px, auto);
}

.button {
  display: block;
  appearance: none;
  width: percentage(calc(210 / 375));
  max-width: 210px;
  margin: 18px auto 0;
  background: #000;
  border: 1px solid #000;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  margin-top: 10px !important  ;
  @include pxToClampMobile('padding', auto, 13px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);

  &.outline {
    background: none;
    color: #000;
  }
}

.button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


.buttonCont {
  @include pxToClampMobile('margin-top', auto, 40px, auto);

}