@import '../../../css/global';

.wrap {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  padding: 0 0 80px;
  margin: 0 auto;

  @include pxToClampMobile('font-size', auto, 24px, auto);
}
