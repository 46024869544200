@import '../../../css/global';

.wrap {
  padding: 0 0 80px;

  img {
    width: percentage(calc(140 / 375));
    max-width: 140px;
    margin: 0 auto;
    margin-top: 24px;
  }
}

.prefix {
  line-height: 1.2;
  @include pxToClampMobile('font-size', auto, 15px, auto);
}

.title {
  max-width: 330px;
  margin: 20px auto 24px;

  font-weight: 900;
  text-transform: uppercase;
  line-height: 0.9;


  @include pxToClampMobile('font-size', auto, 40px, auto);
}

.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}
