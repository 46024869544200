@import '../../../css/global';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #fff;

  font-weight: bold;
  text-align: center;
  color: #000;
  line-height: 1.2;

  @include pxToClampMobile('font-size', auto, 14px, auto);
  @include pxToClampMobile('min-height', auto, 60px, auto);
  @include letter-spacing-percentage(-1)

}

.inner {
  max-width: 335px;
  margin: 0 auto;
  white-space: pre-wrap;

  @media (min-width: 768px) {
    max-width: none;
  }
}
