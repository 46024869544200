@import '../../../css/global';

.wrap {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 5;

  font-size: 0.9rem;
  line-height: 1;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;

  a {
    text-decoration: none;
  }
}

.terms {
  position: absolute;
  bottom: 0;

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.icon {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
