@import '../../../css/global';

.wrap {
  &.header {
    // display: none;
    position: absolute;
    pointer-events: none;
    top: 52px;
    left: 0;
    width: 100%;
    z-index: 99;

    @media (max-width: 340px) and (min-height: 670px), (max-width: 360px) and (min-height: 710px), (min-height: 740px) {
      display: block;
    }
  }

  &.home {
  }
}

.logo {
  &.header {
    width: percentage(calc(80 / 375));
    max-width: 82px;
    margin: 0 auto;
  }

  &.home {
    width: percentage(calc(210 / 375));
    max-width: 210px;
    margin: 0 auto 100px;
  }

  img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
}
