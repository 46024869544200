@import '../../../css/global';

.wrap {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  background-size: cover;
  background-position: center;
  z-index: 9999;

  @media (max-height: 500px) and (min-aspect-ratio: 1/1) {
    display: flex;
  }
}

.content {
  max-width: 330px;
  font-size: 22px;

  @media (min-width: 600px) and (min-height: 220px) {
    max-width: 360px;
    font-size: 24px;
  }
  @media (min-width: 700px) and (min-height: 250px) {
    max-width: 390px;
    font-size: 26px;
  }
}
