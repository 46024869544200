@import '../../../css/global';

.wrap {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  margin: 0 auto;
  text-transform: uppercase;
  // text-shadow: 0 0 8px rgba(0, 0, 0, 0.05);

  svg {
    margin: 0 auto;
    margin-top: 12px;
    width: percentage(calc(140 / 375));
    max-width: 140px;
    height: auto;
  }
}

.title {
  line-height: 0.9;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('font-size', auto, 30px, auto);
}

.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}

.prize {
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1.2;

  @include pxToClampMobile('padding-top', auto, 15px, auto);
  @include pxToClampMobile('font-size', auto, 20px, auto);
}

.codeTitle {
  font-weight: 600;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);
}

.code {
  font-weight: 700;

  @include pxToClampMobile('padding-top', auto, 10px, auto);
  @include pxToClampMobile('font-size', auto, 32px, auto);
}

.panel {
  width: 260px;
  padding: 20px 12px;
  margin: 0 auto -70px;
  background: #fefefe;
  border-radius: 6px;

  @include pxToClampMobile('margin-top', auto, 26px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);
}

.panelTitle {
  font-weight: 900;
  color: #d360b6;
}

.panelBody {
  padding: 6px 0 0;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.2;
  text-transform: none;
  color: #000;
}

.button {
  display: block;
  appearance: none;
  width: percentage(calc(210 / 375));
  max-width: 210px;
  margin: 18px auto 0;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: #17181a;

  @include pxToClampMobile('padding', auto, 13px, auto);
  @include pxToClampMobile('margin-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);

  &.outline {
    background: none;
    color: #fff;
  }
}