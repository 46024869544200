@import '../../../css/global';

.wrap {
  @include pxToClampMobile('padding-top', auto, 20px, auto);
  @include pxToClampMobile('padding-bottom', auto, 110px, auto);
}

.title {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  margin: 0 auto;

  line-height: 0.9;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('font-size', auto, 30px, auto);
}

.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}


.body {
  @include pxToClampMobile('padding-top', auto, 30px, auto);
  @include pxToClampMobile('font-size', auto, 24px, auto);
}
