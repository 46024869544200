// ======================================================================
// GENERAL
// ======================================================================

@mixin hidefont() {
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}

@mixin noselect() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin allowselect() {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

@mixin nodrag() {
  -webkit-user-drag: none;
  user-drag: none;
}

@mixin touchscroll() {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

// ======================================================================
// ARRAY MAP
// ======================================================================

@function map-key() {
  @return $map-key;
}

@function map-val($key: null) {
  @if $key != null {
    @return map-get(map-get($map-array, $map-key), $key);
  } @else {
    @return map-get($map-array, $map-key);
  }
}

@function list-val() {
  @return $map-val;
}

@mixin breakpoint-map($array) {
  $map-array: $array !global;
  @each $key, $val in $array {
    $map-key: $key !global;
    @if $key == 0 {
      @content;
    } @else {
      @media (min-width: map-get($breakpoints,$key)) {
        @content;
      }
    }
  }
}

@mixin breakpoint-map-filtered($array, $zero: true) {
  $map-array: $breakpoints !global;
  @if $zero == true {
    $map-key: 0 !global;
    @content;
  }
  @each $key in $array {
    $map-key: $key !global;
    $breakpoint: map-get($breakpoints, $key);
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin array-map($array) {
  $map-array: $array !global;
  @each $key, $val in $array {
    $map-key: $key !global;
    @content;
  }
}

@mixin list-map($list) {
  @each $val in $list {
    $map-val: $val !global;
    @content;
  }
}

// get the largest value from a breakpoint map
@function map-largest-val($array) {
  $bp-key: 0;
  @each $key, $val in $breakpoints {
    @if (map-has-key($array, $key)) {
      $bp-key: $key;
    }
  }
  @return map-get($array, $bp-key);
}

// get the closest value from a breakpoint map (<=)
@function map-closest-val($array, $key) {
  @if (map-has-key($array, $key)) {
    @return map-get($array, $key);
  } @else {
    $bp-key: 0;
    @each $key2, $val in $breakpoints {
      @if ($key2 == $key) {
        @return map-get($array, $bp-key);
      }
      @if (map-has-key($array, $key2)) {
        $bp-key: $key2;
      }
    }
  }
  @return '';
}

// breakpoint map can be used to map values to breakpoints as follows:
// .element {
//   @include breakpoint-map(
//     (
//       0: 16px,
//       md: 18px,
//       lg: 20px
//     )
//   ) {
//     font-size: map-val();
//   }
// }

// array map can be used as follows:
// @include array-map(
//   (
//     red: #f00,
//     green: #0f0,
//     blue: #00f
//   )
// ) {
//   .element--#{'' + map-key()} {
//     background: #{map-val()};
//   }
// }

// ======================================================================
// FONT SIZE
// ======================================================================

@function pxToRem($size) {
  $remSize: calc($size / 10px);
  @return #{$remSize}rem;
}

@function stripUnits($number) {
  @return calc($number / ($number * 0 + 1));
}

@function calculateClamp($min, $preferred, $max, $preferredViewport) {
  $clamp_parameter_1: pxToRem($min);
  $clamp_parameter_2: stripUnits((calc(($preferred) / $preferredViewport)) * 100) * 1vw;
  $clamp_parameter_3: pxToRem($max);

  @return clamp(#{$clamp_parameter_1}, #{$clamp_parameter_2}, #{$clamp_parameter_3});
}

@mixin pxToClampGeneral($property_name, $min, $preferred, $max, $preferredViewport) {
  #{$property_name}: calculateClamp($min, $preferred, $max, $preferredViewport);
}

// DEVICE SIZES

@mixin pxToClampDevice($property_name, $min, $preferred, $max, $min_multiplier, $max_multiplier, $preferredViewport) {
  @if $min == auto {
    $min: round($preferred * $min_multiplier);
  }
  @if $max == auto {
    $max: round($preferred * $max_multiplier);
  }

  #{$property_name}: pxToRem($preferred);
  @include pxToClampGeneral($property_name, $min, $preferred, $max, $preferredViewport);
}

@mixin pxToClampMobile($property_name, $min: auto, $preferred, $max: auto) {
  $min_multiplier: calc(320 / 375);
  $max_multiplier: calc(375 / 375);
  @include pxToClampDevice($property_name, $min, $preferred, $max, $min_multiplier, $max_multiplier, 375px);
}

@mixin pxToClampTablet($property_name, $min: auto, $preferred, $max: auto) {
  $min_multiplier: calc(580 / 768);
  $max_multiplier: calc(880 / 768);
  @include pxToClampDevice($property_name, $min, $preferred, $max, $min_multiplier, $max_multiplier, 768px);
}

@mixin pxToClampDesktop($property_name, $min: auto, $preferred, $max: auto) {
  $min_multiplier: calc(1024 / 1440);
  $max_multiplier: 1; //calc(1660 / 1440);
  @include pxToClampDevice($property_name, $min, $preferred, $max, $min_multiplier, $max_multiplier, 1440px);
}

@mixin letter-spacing-percentage($percentage) {
  letter-spacing: #{calc($percentage / 100)}em;
}