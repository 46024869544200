@import '../../../css/global';

.wrap {
  width: 100%;
  height: 0;
  padding: 0 0 100%;

  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.outer {
  position: absolute;
  top: -25%;
  left: 0;
  width: 100%;
}

.inner {
  width: 110%;
  margin: -5%;
}

.svg {
  width: 100%;

  tspan {
    font-size: 0.9rem;
  }
}
