@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-XLight_Web.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-XLightItalic_Web.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-Light_Web.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-LightItalic_Web.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-Book_Web.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-BookItalic_Web.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-Medium_Web.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-MediumItalic_Web.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-Bold_Web.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-BoldItalic_Web.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-Black_Web.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/gotham/GothamSSm-BlackItalic_Web.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
	font-family: 'VeloWangle';
	src: url('../fonts/wangle/VeloWWangle.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}