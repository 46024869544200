@import '../../../css/global';

.wrap {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  margin: 0 auto;
}

.title {
  line-height: 0.9;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('font-size', auto, 36px, auto);
}


.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}
