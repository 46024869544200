@import '../../../css/global';

.title {
  width: percentage(calc(340 / 375));
  max-width: 340px;
  margin: 0 auto;

  line-height: 0.9;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('font-size', auto, 40px, auto);
}

.velo {
  font-family: 'VeloWangle';
  line-height: 1;
  font-weight: 600;
}

.body {
  width: percentage(calc(315 / 375));
  max-width: 315px;
  margin: 0 auto;
  line-height: 1.3;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('padding-bottom', auto, 40px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);
  @include letter-spacing-percentage(-1)
}

.form {
}

.fields {
  select {
    display: block;
    appearance: none;
    width: percentage(calc(210 / 375));
    max-width: 210px;
    padding: 11px 36px 11px 12px;
    margin: 0 auto;

    background-image: url(../../../images/dropdown.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    border-radius: 6px;

    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: #000;
  }

  &.multiple {
    display: flex;
    justify-content: center;

    select {
      width: 130px;
      margin: 0 5px;

      @media (min-width: 360px) {
        width: 140px;
      }
    }
  }

  + .fields {
    margin-top: 10px;
  }
}

.error {
  min-height: 10px;
  padding: 10px 0 0;
  font-weight: 600;
  color: #fdcbe0;

  @include pxToClampMobile('font-size', auto, 13px, auto);
}

.button {
  display: block;
  appearance: none;
  width: percentage(calc(210 / 375));
  max-width: 210px;
  margin: 18px auto 0;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: #17181a;

  @include pxToClampMobile('padding', auto, 13px, auto);
  @include pxToClampMobile('margin-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);

  &.outline {
    background: none;
    color: #fff;
  }
}
